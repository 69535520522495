@use '../abstracts' as *;

/* ##############################################################################

    PAGE

############################################################################## */

/* related_page
********************************************** */
.related_page--ttl {
  font-size: calc-fz(24);
  font-weight: normal;
  line-height: 1;
  letter-spacing: -0.02em;
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.related_page--list {
  position: relative;
  line-height: 1.6;
  z-index: 1;
  li {
    border-top: 1px solid var(--clr-mono-03);
    &:last-child {
      border-bottom: 1px solid var(--clr-mono-03);
    }
  }
  .ttl {
    font-weight: normal;
  }
  .ttl [lang="ja"] {
    font-size: calc-fz(40);
    letter-spacing: calc-fz(1.2);
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
  .ttl [lang="en"] {
    font-size: calc-fz(18);
    font-weight: 600;
    letter-spacing: calc-fz(1);
    text-transform: uppercase;
    transition: var(--transit-default);
    @include mq(sp) {
      line-height: 1.5;
      font-size: sprem(14);
    }
  }
}
.related_page--list li {
  .hover {
    padding-block: rem(32);
    border-bottom: 1px solid var(--clr-text-light);
    border-left: 0 solid var(--clr-primary-default);
    transition: .8s;
    @include mq(sp) {
      padding-block: sprem(24);
    }
  }
  @include mq(sp, min, ps) {
    &::before {
      content: "";
      position: absolute;
      right: rem(132);
      top: 50%;
      width: rem(340);
      aspect-ratio: 340 / 227;
      background-image: var(--hover_img);
      background-position: center;
      background-size: cover;
      translate: 0 -50%;
      opacity: 0;
      filter: blur(1.25rem);
      transition: .8s;
      z-index: 1;
    }
    &:hover {
      &::before {
        opacity: 1;
        filter: blur(0);
      }
      .hover {
        padding-left: rem(80);
        background: var(--clr-wht);
        box-shadow: 0px rem(4) rem(24) 0 rgba(0, 0, 0, 0.10);
      }
    }
  }
  .link-arrow {
    background: none;
    border: 0;
    @include mq(sp, min, ps) {
      margin-right: rem(12);
    }
    svg {
      -webkit-filter: brightness(1) invert(0);
      filter: brightness(1) invert(0);
    }
  }
}