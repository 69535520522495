@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */

.fixed-area {
  position: fixed;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 99;
}
.footer {
  position: relative;
  overflow: clip;
  .label {
    border-color: var(--clr-wht);
  }
}

.footer--logo img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  @include mq(med, min, ps) {
    width: rem(853) !important;
    height: auto;
  }
  @include mq(med) {
    width: sprem(220) !important;
    height: auto;
  }
}

.footer--address {
  display: flex;
  flex-direction: column;
  gap: rem(10);
  letter-spacing: .08em;
  &--label {
    width: fit-content;
    padding-inline: rem(8);
    line-height: 1.5;
    background-color: #5a5140;
    @include mq(sp) {
      padding: sprem(2) sprem(8);
    }
  }
}

.map-icon_wrap {
  @include center-flex;
  @include rect(52);
  border: 1px solid var(--clr-wht);
  background-color: var(--clr-wht);
  border-radius: 9999px;
  aspect-ratio: 1;
  &:hover {
    background-color: var(--clr-text-primary);
    img {
      -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }
  }
  img {
    transform: var(--transit-default);
  }
}

.btn-access a {
  position: relative;
  z-index: 1;
  display: block;
  padding: rem(16) rem(28);
  width: fit-content;
  min-width: min(rem(223), 100%);
  color: var(--clr-wht);
  line-height: 1;
  border: 1px solid rgb(255 255 255 / 40%);
  border-radius: 9999px;
  letter-spacing: .08em;
  text-decoration: none;
  &:hover {
    background-color: var(--clr-wht);
    color: var(--clr-text-primary);
  }
}

/* footer_top
********************************************** */

.footer_top {
  padding-top: rem(120);
  @include mq(sp) {
    padding-top: sprem(80);
  }
}
.footer_top > .inner {
  padding-bottom: rem(40);
  border-bottom: 1px solid #9c9992;
}

/* ---fnav --- */
.fnav--menu {
  @include mq(med, min, ps) {
    margin-right: rem(56);
  }
  &--ttl {
    margin-top: rem(10);
    color: #DDD;
    @include write-v;
    letter-spacing: .4em;
    @include mq(med) {
      font-size: sprem(12);
    }
  }
}
.fnav--menu a {
  position: relative;
  display: block;
  padding: 0 1em;
  font-size: calc-fz(20);
  line-height: 1.9;
  letter-spacing: calc-fz(3.2);
  @include mq(med) {
    font-size:  sprem(15);
  }
  &:not([href="javascript:void(0);"]):hover::after {
    background-color: var(--clr-wht);
    // animation: btn-line .5s ease-out 0s 1 normal forwards;
  }
  &:where([href="javascript:void(0);"]):hover {
    color: var(--clr-wht);
  }
}
.fnav--menu .sub-menu a::before {
  content: "";
  margin-inline: rem(8);
  @include rect(10,1);
  display: inline-block;
  vertical-align: super;
  background-color: var(--clr-wht);
}
.fnav_sub--menu {
  @include mq(med) {
    gap: 0 !important;
  }
  a {
    display: flex;
    align-items: center;
    gap: rem(8);
    letter-spacing: .18em;
    svg {
      fill: var(--clr-wht);
    }
  }
}

/* ---sns_area --- */
.sns_area {
  margin-bottom: rem(64);
  @include mq(sp) {
    justify-content: start;
    border-bottom: 1px solid var(--clr-wht);
    margin-bottom: sprem(8);
  }
}
.sns_area a {
  display: grid;
  place-items: center;
  @include rect(40);
  @include mq(sp) {
    @include sprect(28)
  }
  &:hover {
    opacity: 0.7;
  }
}
.sns_area svg {
  @include rect(24);
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  &.icon-x {
    @include rect(22);
  }
  &.icon-note {
    @include rect(20);
  }
  @include mq(sp) {
    width: sprem(20) !important;
    height: auto !important;
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: rem(56) 0;
  @include mq(med) {
    padding: sprem(32) 0;
  }
}
.footer_btm:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @include mq(sp) {

  }
}

.return_top a {
  display: flex;
  align-items: center;
  gap: rem(16);
  line-height: 1;
  @include mq(med) {
    justify-content: end;
  }
  &::after {
    content: "";
    display: inline-block;
    @include rect(52);
    border-radius: rem(30);
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    border: 1px solid rgb(255 255 255 / 40%);
    background: url(../images/common/arrow.svg) no-repeat center center;
    rotate: -90deg;
    transition: var(--transit-default);
  }
}
.privacy {
  @include mq(sp) {
    text-align: center;
    margin-bottom: 0.5em;
  }
}
.privacy a:hover {
  opacity: 0.7;
}
.copyright {
  gap: 1em;
  justify-content: center;
  @include mq(sp) {
    gap: 0.5em;
    text-align: center;
  }
  p {
    color: #bababa;
    justify-content: center;
    text-align: center;
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}
