@use "../abstracts" as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */

@keyframes btn-line {
  0% {
      transform: scale(1,1)
  }
  50% {
      transform-origin: right top;
      transform: scale(0,1)
  }
  51% {
      transform-origin: left top
  }
  100% {
      transform: scale(1,1);
      transform-origin: left top
  }
}

@keyframes btnBorder {
  0% {
    background-position: 100%;
  }
  50% {
    background-position: 0%;
  }
  50.1% {
    background-position: 200%;
  }
  100% {
    background-position: 100%;
  }
}

@keyframes TitleGradation {
  0% {
    background-position: 150% 100%;
  }
  100% {
    background-position: -50% 50%;
  }
}

@keyframes photo-move {
  0%   { transform: translateX(0); }
  100% { transform: translateX(rem(-100)); }
}

@keyframes slide_idling {
  0% {
      translate: 0 0;
  }
  100% {
      translate: -120px 0;
  }
}
@keyframes slide_idling-sp {
  0% {
      translate: 0 0;
  }
  100% {
      translate: -60px 0;
  }
}

@keyframes scrollAnimation {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(rem(-3000));
  }
}

@keyframes scrollAnimation_sp {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(sprem(-2300));
  }
}

@keyframes btn_arrow {
  0%,
  100% {
    translate: 0 0;
    opacity: 1;
  }
  30% {
    translate: 50% 0;
    opacity: 0;
  }
  60% {
    translate: -50% 0;
    opacity: 0;
  }
}

@keyframes photo-scale {
  0% {
      transform: scale(1)
  }

  100% {
      transform: scale(1.2)
  }
}