@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: var(--clr-wht);
  height: rem($header-hgt);
  z-index: z(header);
  transition: 1s;
  mix-blend-mode: exclusion;
  @include mq(med, min, ps) {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      opacity: 0;
      background: url(../images/common/bg-pattern.jpg) repeat center center;
      transition: 1s;
    }
  }
  &.active {
    @include mq(med, min, ps) {
      height: rem(185) !important;
    }
    background: none;
    > .inner::before {
      opacity: 0;
    }
    .header--logo img {
      @include mq(med, min, ps) {
        width: rem(647) !important;
      }
    }
  }
  > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    @include mq(med, min, ps) {
      padding-inline: rem(106);
    }
    @include mq(med, min, ps) {
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        inset-inline: rem(108);
        height: 1px;
        background-color: var(--clr-wht);
      }
    }
  }
  &.is-hidden:not(.active) {
    transform: translate(0, -110%);
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    & > .inner {
      padding-left: sprem(24);
    }
  }
}
.template-contact-simple .header {
  position: absolute;
}
.header-sm {
  .hero--message {
    opacity: 0;
  }
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
}

.home .header > .inner {
  transition-delay: 1s;
}
.home .header:not(.header-sm) {
  @include mq(med, min, ps) {
    height: rem($header-hgt-top);
    .gnav_btn {
      top: rem(40);
    }
  }
  .inner::before {
    opacity: 0;
  }
  .gnav_btn--txt {
   color: var(--clr-wht);
  }
  .gnav_btn--lines span {
    background-color: var(--clr-wht);
  }
  &:not(.active) .header--logo img {
    @include mq(med, min, ps) {
      width: rem(853) !important;
    }
  }
}

/* --- logo -- */
.header--logo {
  position: relative;
  z-index: 999;
}
.header--logo a,
.footer--logo a {
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}
.header--logo img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  transition: var(--transit-default);
  @include mq(med, min, ps) {
    width: rem(647) !important;
    height: auto;
  }
  @include mq(med) {
    width: sprem(215) !important;
    height: auto;
  }
}

.active .header--logo img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

/* gnav
********************************************** */
.gnav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  pointer-events: none;
  overflow: auto;
  overscroll-behavior-y: contain;
  -webkit-overflow-scrolling: touch;
  transition: var(--transit-default);
  z-index: 9999;
  @include mq(med, min, ps) {
    padding-block: rem(64);
    padding-inline: rem(106);
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      opacity: 0;
      background: #000;
      z-index: -1;
      pointer-events: none;
      transition: 1600ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      opacity: 0;
      background: url(../images/common/bg-navi.png) no-repeat center left / 100% auto;
      z-index: 0;
      pointer-events: none;
      transition: 1600ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
  @include mq(med) {
    padding: calc(rem($header-hgt-sp) + sprem(16)) sprem(40) sprem(32);
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transition: 1600ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-property: opacity, visibility;
    backdrop-filter: blur(rem(5));
    @include mq(med) {
      background: rgb(0 0 0 / 90%);
    }
    @include mq(med, min, ps) {
      &::before {
        opacity: .9;
      }
    }
    &::after {
      opacity: 1;
    }
  }
}
.gnav--link {
  @include mq(med, min, ps) {
    font-size: calc-fz(18);
  }
  @include mq(med) {
    font-size: sprem(18);
  }
}
.sns_area {
  @include mq(med) {
    padding-block: sprem(32);
    margin-bottom: sprem(32);
  }
}

@include mq(med, min, ps) {
  .gnav--menu_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: rem(138);
    margin-top: rem(110);
    width: fit-content;
    z-index: 1;
    .sns_area {
      width: 100%;
    }
  }
  
  .gnav--ttl_wrap {
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 1;
    .gnav--ttl {
      padding-left: rem(45);
      margin-bottom: rem(102);
      line-height: 1.6;
      letter-spacing: .012em;
    }
    .copyright {
      justify-content: start;
    }
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
  }
}
.gnav--link {
  @include mq(med) {
    display: block;
    padding: sprem(10) 0;
    line-height: 1.6;
  }
  @include mq(med, min, ps) {
    text-align: center;
    line-height: 1.9;
    letter-spacing: .0054em;
  }
}
.gnav--menu > li:not(.menu-item-has-children) a::before {
  @include mq(med) {
    content: "";
    float: inline-end;
    inline-size: sprem(7);
    margin-block: calc((1lh - sprem(7)) / 2);
    aspect-ratio: 1;
    border-top: 1px solid var(--clr-wht);
    border-right: 1px solid var(--clr-wht);
    transform: rotate(45deg);
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  margin-left: rem(10);
  z-index: 100;
  @include mq(med) {
    display: none;
    margin-bottom: sprem(16);
  }
}
.head_sub_nav a::before {
  content: "";
  margin-inline: rem(8);
  @include rect(10,1);
  display: inline-block;
  vertical-align: super;
  background-color: var(--clr-wht);
}
.head_sub_nav {
  @include mq(med) {
  a { letter-spacing: .18em; }
  }
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav a {
  @include mq(med) {
    padding-left: sprem(8);
    padding-block: sprem(4);
  }
  &:hover {
    @include mq(med, min, ps) {
      color: var(--clr-primary-default);
    }
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: sprem(-24);
    @include sprect(56);
    transition: var(--transit-default);
    color: var(--clr-wht);
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(11);
      height: 1px;
      border-bottom: 1px solid var(--clr-wht);
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}
.gnav_sub_toggle.active::after {
  opacity: 0;
}


/* ---btn --- */
.gnav_btn,
.tel_btn {
  position: absolute;
  inset-block: 0;
  margin: auto;
  cursor: pointer;
  z-index: 9999;
}
.gnav_btn {
  right: rem(108);
  transition: var(--transit-default);
  @include mq(med) {
    top: sprem(35);
    right: sprem(18);
    flex-direction: column-reverse;
    gap: sprem(4);
    text-align: center;
  }
}
.gnav_btn--txt {
  font-weight: 400;
  letter-spacing: calc-fz(3.2);
  white-space: nowrap;
  transition: var(--transit-default);
  @include mq(sp) {
    font-size: sprem(12);
    letter-spacing: sprem(3.2);
  }
}
.gnav_btn--lines {
  position: relative;
  @include rect(69, 23);
  @include mq(med) {
    @include sprect(48, 13);
  }
}
.gnav_btn--lines span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--clr-wht);
  transition: var(--transit-default);
  &:nth-of-type(1) {
    top: 0;
  }
  &:nth-of-type(2) {
    top: 50%;
  }
  &:nth-of-type(3) {
    bottom: 0;
  }
}
.active .gnav_btn--txt {
  color: var(--clr-wht);
}
.active .gnav_btn--lines span {
  background-color: var(--clr-wht);
  &:nth-of-type(1) {
    transform: translateY(rem(10)) rotate(-30deg);
    @include mq(sp) {
      transform: translateY(sprem(5)) rotate(-15deg);
    }
  }
  &:nth-of-type(2) {
    opacity: 0;
  }
  &:nth-of-type(3) {
    transform: translateY(rem(-10)) rotate(30deg);
    @include mq(sp) {
      transform: translateY(sprem(-6)) rotate(15deg);
    }
  }
}
.tel_btn {
  @include mq(med) {
    right: sprem(64);
  }
  a {
    @include mq(med) {
      display: flex !important;
      width: 100%;
      height: 100%;
    }
  }
  svg {
    @include mq(med) {
      @include sprect(24);
      fill: var(--clr-primary-default);
    }
  }
}
.gnav--cta {
  text-align: center;
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.gnav--tel,
.gnav--contact {
  @include mq(med, min, ps) {
    margin-left: rem(8);
  }
}
.gnav--tel a {
  display: inline-flex !important;
  flex-direction: column;
  margin-inline: auto;
}
.gnav--tel .tel {
  font-size: calc-fz(20);
}
.gnav--tel .num {
  font-size: calc-fz(24);
}
.gnav--tel .hours {
  font-size: calc-fz(14);
}
.gnav--contact a {
  padding: rem(24);
  @include mq(med, min, ps) {
    border-radius: 0;
  }
  @include mq(med) {
    padding: sprem(10) sprem(16);
    margin-left: auto;
    margin-right: auto;
  }
}
