@use "../abstracts" as *;

/* anchor_link
********************************************** */
.anchor_link--list_wrap {
  border-block: 1px solid var(--clr-mono-03);
  margin-bottom: rem(40);
  @include mq(sp) {
    overflow: auto;
  }
}
.anchor_link--list {
  @include mq(sp) {
    flex-wrap: inherit;
    white-space: nowrap;
    width: 100%;
    margin-inline: calc(50% - 50vw);
    padding-inline: 7%;
    overflow: auto;
  }
}
.anchor_link--list li {
  position: relative;
  min-height: rem(96);
  &::after {
    content: "";
    position: absolute;
    right: 0;
    inset-block: 0;
    margin-block: auto;
    @include rect(1,24);
    background-color: var(--clr-mono-03);
  }
  &:nth-child(5n+1)::before {
    content: "";
    position: absolute;
    left: 0;
    inset-block: 0;
    margin-block: auto;
    @include rect(1,24);
    background-color: var(--clr-mono-03);
  }
  @include mq(sp) {
    width: auto;
    min-height: sprem(64);
    &:first-child {
      margin-left: sprem(-16);
    }
    &:last-child {
      margin-right: sprem(-10);
    }
  }
}
.anchor_link--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em 2.5em;
  line-height: var(--line-height-hdr);
  @include mq(sp) {
    padding: sprem(14) sprem(24) sprem(14) sprem(16);
  }
  &::after {
    content: "";
    display: inline-block;
    margin-left: rem(12);
    @include rect(8);
    border-bottom: 1px solid;
    border-right: 1px solid;
    rotate: 45deg;
    transition: var(--transit-default);
  
  }
}
