@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: rem(14) rem(64) rem(14) rem(32);
  width: rem(420);
  min-width: min(rem(358), 100%);
  font-size: calc-fz(18);
  color: var(--clr-text-primary);
  border: 1px solid rgba(0, 0, 0, 0.21);
  text-decoration: none;
  -webkit-backdrop-filter: blur(rem(20));
  backdrop-filter: blur(rem(20));
  @include mq(med) {
    font-size: sprem(16);
  }
  @include mq(sp) {
    min-width: 100%;
    width: 100%;
    padding: rem(14) rem(64) rem(14) rem(16);
  }
  &:hover {
    color: var(--clr-wht);
    background-color: var(--clr-blk);
    &::before {
      width: 0;
    }
    &::after {
      width: rem(80);
      transition-delay: 0;
      @include mq(sp) {
        width: sprem(54);
        left: calc(100% - sprem(28));
      }
    }
  }
  &:where(:not([rel*="nofollow"]):not([href$=".docx"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: rem(-40);
    @include rect(80,1);
    background: var(--clr-text-primary);
    transition: var(--transit-default);
    @include mq(sp) {
      @include sprect(56,1);
      right: rem(-24);
    }
  }
  &:where(:not([rel*="nofollow"]):not([href$=".docx"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: calc(100% - rem(40));
    height: 1px;
    width: 0;
    background: linear-gradient(to right, #FFF 0%, #FFF 50%, var(--clr-text-primary) 50%, var(--clr-text-primary) 100%);
    transition: var(--transit-default);
    transition-delay: .3s;

  }
  svg {
    right: rem(24);
    fill: currentColor;
  }
}
.btn.btn-ctr a {
  @include auto-margin;
}

/* --- btn-naked --- */
.btn-naked a {
  position: relative;
  display: flex;
  align-items: center;
  gap: rem(16);
  line-height: 1;
  font-size: calc-fz(24);
  letter-spacing: calc-fz(1.2);
  width: fit-content;
  transition: var(--transit-default);
  @include mq(med) {
    font-size: sprem(16);
  }
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-wht);
  border: 1px solid rgb(255 255 255 / 40%);
  &::before,
  &::after {
    background: var(--clr-wht) !important;
  }
  &:hover {
    color: var(--clr-blk);
    background-color: var(--clr-wht);
    &::after {
      background: linear-gradient(to right, var(--clr-text-primary) 0%, var(--clr-text-primary) 57%, var(--clr-wht) 57%, var(--clr-wht) 100%) !important;
    }
  }
  svg {
    fill: var(--clr-wht);
  }
  &:hover svg {
    fill: var(--clr-wht);
  }
}

/* --- btn-home --- */
.btn-home a {
  &::before {
    right: rem(-100);
    @include rect(228,1);
    background: var(--clr-text-primary);
    @include mq(sp) {
      @include sprect(72,1);
      right: rem(-16);
    }
  }
  &::after {
    left: calc(100% - rem(128));
    background: var(--clr-text-primary);
    @include mq(sp) {
      width: sprem(54);
      left: calc(100% - sprem(56));
    }
  }
  &:hover {
    &::before {
      width: 0;
    }
    &::after {
      width: rem(228);
      transition-delay: 0;
      background: linear-gradient(to right, #FFF 0%, #FFF 56%, var(--clr-text-primary) 56%, var(--clr-text-primary) 100%);
    }
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--clr-secondary-default);
  &:hover {
    background-color: var(--clr-secondary-dark);
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--clr-primary-default);
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--clr-primary-default);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280), 100%);
    }
  }
  a {
    width: fit-content;
    min-width: 100%;
    height: 100%;
  }
}

/* link-arrow
********************************************** */
.link-arrow {
  display: grid;
  place-content: center;
  flex-shrink: 0;
  @include rect(52);
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #0d0d0d;
  border: 1px solid #0d0d0d;
  transition: var(--transit-default);
  svg {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
    transition: var(--transit-default);
  }
}

.link_wrap:hover {
  .link-arrow {
    background-color: var(--clr-wht);
  }
  svg {
    -webkit-filter: brightness(1) invert(0);
    filter: brightness(1) invert(0);
    animation: btn_arrow .4s forwards;
  }
}

.cat_list a,
.breadcrumbs > span > span > a,
a .txt--wrap,button .txt--wrap {
  position: relative
}

.cat_list a::before,
.breadcrumbs > span > span > a::before,
button .txt--wrap::before,
a .txt--wrap::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  height: 1px;
  width: 100%;
  background: 0 0;
  transition: all .4s ease-out;
  transition: var(--transit-default)
}

.cat_list a:hover::before,
.breadcrumbs > span > span > a:hover::before,
button:hover .txt--wrap::before,
a:hover .txt--wrap::before,
.gnav--menu > .current-menu-item > a > span > .txt--wrap::before {
  background: currentColor;
  animation: btn-line .5s ease-out 0s 1 normal forwards
}

.btn-fixed {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.1);
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    transition: var(--transit-default);
  }
  &:hover::before {
    opacity: 1;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(36);
    width: fit-content;
    min-width: rem(475);
    height: rem(97);
    padding: rem(14) rem(32) rem(14) rem(128);
    color: var(--clr-wht);
    font-size: calc-fz(17);
    background: url(../images/common/bg-btn-fixed.png) repeat center center;
    border: none;
    @include mq(sp) {
      padding: sprem(16) sprem(28);
      min-width: 100%;
      width: 100%;
      height: sprem(58);
      &::before, &::after {
        display: none;
      }
    }
    @include mq(sp, min, ps) {
      &::before {
        right: auto;
        left: rem(-128);
        @include rect(228,1);
        background: linear-gradient(to right, #ba3b22 0%, #ba3b22 56%, #FFF 56%, #FFF 100%);
      }
      &::after {
        right: calc(100% - rem(100));
        left: auto;
        background: linear-gradient(to right, #ba3b22 0%, #ba3b22 56%, #FFF 56%, #FFF 100%);
      }
    }
    .font-en {
      color: #c8be9d;
    }
    &:hover {
      color: var(--clr-wht);
      background-color: var(--clr-text-primary);
      @include mq(sp, min, ps) {
        &::before {
          width: 0;
        }
        &::after {
          width: rem(228);
          transition-delay: 0;
        }
      }
    }
  }
}