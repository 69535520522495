@use "../abstracts" as *;

/* breadcrumbs
**************************************** */
.breadcrumbs {
  @include mq(sp, min, ps) {
    position: absolute;
    right: 0;
    bottom: rem(73);
    padding: rem(24) 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include mq(sp) {
    overflow: auto;
  }
}
.single .breadcrumbs {
  @include mq(med, min, ps) {
    position: relative;
    inset: auto;
    padding-inline: rem(106);
    text-align: right;
  }
  @include mq(med) {
    width: 84%;
    margin: auto;
    white-space: nowrap;
  }
}
.breadcrumbs a {
  color: var(--clr-text-secondary);
  &:hover {
    
  }
}
.breadcrumbs span span:not(:last-child)::after {
  content: "";
  display: inline-block;
  margin: 0.15em .3em 0.15em .5em;
  @include rect(6);
  border-top: 1px solid var(--clr-text-secondary);
  border-right: 1px solid var(--clr-text-secondary);
  rotate: 45deg;
  transition: var(--transit-default);
}
.breadcrumbs .breadcrumb_last {
  color: var(--clr-text-primary);
}