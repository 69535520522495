@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}
.icon-new_tab {
  @include rect(14);
}
.icon-pdf {
  @include rect(27,12);
}
.lps_sec {
  position: relative;
  overflow-x: clip;
  &:nth-child(odd) {
    .lps_sec-bg {
      right: rem(-280);
      @include mq(sp) {
        top: sprem(-160);
        left: sprem(24);
        rotate: 90deg;
      }
    }
  }
  &:nth-child(even) {
    .lps_sec-bg {
      left: rem(-280);
      @include mq(sp) {
        left: auto;
        right: sprem(-40);
      }
    }
  }
}
.lps_sec-bg {
  position: absolute;
  top: rem(16);
  z-index: 0;
  @include rect(932,969);
  background-position: center;
  pointer-events: none;
  @include mq(sp) {
    @include sprect(467,485);
  }
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
  &:not(:last-child) {
    margin-bottom: rem(128);
    @include mq(sp) {
      margin-bottom: sprem(40);
    }
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
}

.page-simple .lps_sec:nth-child(1) {
  padding-top: 0;
}