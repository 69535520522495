@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: calc-clamp($fz-pc, 1080);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-padding-top: rem($header-hgt-fx);
  @include mq(sp, min, ps) {
    @include mq(med) {
      font-size: rem($fz-sp);
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: min(spvw($fz-sp), rem($fz-sp));
    scroll-padding-top: rem($header-hgt-sp);
  }
}
body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  color: var(--clr-text-primary);
  height: 100%;
  word-break: break-word;
  background: url(../images/common/bg-pattern.jpg) repeat center center;
  @include mq(sp, min, ps) {
    // フォントサイズ変更する場合　例：18pxの例：calc-clamp(18, $wid-var)
    font-size: calc-clamp($fz-pc, $wid-var);
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
  &:not(.home) {
    padding-top: rem($header-hgt);
    @include mq(sp) {
      padding-top: rem($header-hgt-sp);
    }
  }
}
* {
  letter-spacing: var(--ltr-space-default);
}
ul,
ol { list-style: none; }
small { 
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a:where([href="javascript:void(0);"]):hover {
  color: var(--clr-text-primary);
  text-decoration: none;
  transition: var(--transit-default);
  outline : none;
}
a:where([href="javascript:void(0);"]),
a:where([href="javascript:void(0);"])::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:focus {
  text-decoration: none !important;
}
a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
  }
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr);
}
svg {
  transition: var(--transit-default);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}

.gradient {
  &__wrap {    
    display: block;
  }
  &__title {
    padding-bottom: rem(16);
    animation: TitleGradation 40s ease infinite;
    background: linear-gradient(to right, var(--clr-text-primary),  #bcb074,var(--clr-text-primary),  #bcb074, var(--clr-text-primary),  #bcb074, var(--clr-text-primary));
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}

.bgclipping {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.hikari--bg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

/*　ニュートラル
********************************************** */
// 下から上にフェードイン
@mixin neutral_base {
	transform: translateY(rem(20));
  opacity: 0;
  transition: all 1s ease;

	&.loaded,
  &.active {
		transform: translateY(0);
    opacity: 1;
	}
}

.load_active,
.scroll_active {
  @include neutral_base;
}

.label {
  font-size: calc-fz(16);
  padding: rem(4) rem(8);
  line-height: 1.4;
  border: 1px solid var(--clr-text-primary);
}