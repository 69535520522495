@use '../abstracts' as *;

/* section-contact
********************************************** */
.section-contact {
  .cta_tel a {
    padding: rem(24) rem(40);
    width: fit-content;
    @include auto-margin;
    @include mq(sp) {
      display: block;
      padding: sprem(16);
    }
  }
}