@use "../abstracts" as *;

/* cta_tel
**************************************** */
.cta_tel a {
  @include center-flex;
  flex-direction: column;
  padding: rem(12);
  line-height: var(--line-height-hdr);
  background-color: rgba(228, 226, 217, 0.60);
  border: 1px solid var(--clr-mono-03);
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  font-size: calc-fz(18);
}
.cta_tel .num {
  font-size: rem(28);
}
.cta_tel .hours {
  font-size: calc-fz(13);
}

/* cta_contact
**************************************** */
.cta_contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(10);
  color: var(--clr-wht);
  border-radius: rem(4);
  background-color: var(--clr-text-primary);
  &::before {
    display: none;
  }
}
