@use "../abstracts" as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: rem(1480);
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: 84%;
  }
}
@include mq(sp) {
 .inner_sp {
  width: 84%;
  max-width: rem(1480);
  margin-left: auto;
  margin-right: auto;
 }
}
.inner-sm {
  max-width: rem(720);
}
.inner-md {
  max-width: rem(920);
}

.inner-md2 {
  max-width: rem(1080);
}
.inner-lg {
  max-width: rem(1560);
}
.inner-xl {
  max-width: rem(1700);
}
.inner-xxl {
  max-width: rem(1860);
}