@use "../abstracts" as *;

/* page_ttl
********************************************** */
.page_ttl {
  position: relative;
  @include mq(sp) {
    overflow-x: clip;
  }
  &-bg {
    position: absolute;
    top: rem(-280);
    left: rem(-32);
    z-index: 0;
    @include rect(942,861);
    pointer-events: none;
    background-position: center;
    @include mq(sp) {
      top: sprem(-24);
      left: sprem(-20);
      @include sprect(467,485);
    }
  }
  > .inner {
    @include mq(sp) {
      width: 100%;
      margin-bottom: sprem(16)
    }
  }
}
@include mq(med) {
  .archive-art .page_ttl {
    height: auto !important;
    padding-block: sprem(12) sprem(16);
  }

}
.page_ttl--bg {
  position: relative;
  margin: 0 auto rem(50);
  width: perc(1820, 1920, '%');
  aspect-ratio: 1820/680;
  overflow: clip;
  z-index: 1;
  transition: var(--transit-default);
  @include mq(sp) {
    width: 94%;
    width: perc(340, 390, '%');
    margin: 0 auto calc((100% - perc(340, 390, '%')) / 2);
    aspect-ratio: 340/280;
  }
  img {
    position: absolute;
    inset: 0 0 -200px 0;
  }
  &.pc-none img {
    @include mq(sp) {
      height: calc(100% + 200px);
      object-fit: cover;
    }
  }
}
@include mq(sp) {
  .page_ttl--bg:has(+ .page_ttl--bg) {
    display: none;
  }
  .page_ttl--bg:not(.pc-none) img {
    height: calc(100% + 200px);
    object-fit: cover;
  }
}
.page_ttl-jp {
  font-size: calc-fz(56);
  letter-spacing: calc-fz(1.68);
  @include mq(sp) {
    font-size: sprem(32);
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: calc-fz(18);
  letter-spacing: calc-fz(1);
  color: var(--clr-text-secondary);
  @include mq(sp) {
    font-size: sprem(16);
  }
}
body:not(.archive,.page-simple,.template-contact,.template-contact-simple) {
  .page_ttl-jp {
    margin-bottom: rem(24);
    @include mq(sp) {
      margin-bottom: sprem(12);
    }
  }
}

.page-simple,
.template-contact,
.template-contact-simple,
.archive {
  .page_ttl {
    flex-direction: column-reverse !important;
  }
  .page_ttl > .inner {
    display: flex;
    flex-direction: column;
  }
  .page_ttl-jp {
    margin-bottom: rem(24);
    font-size: calc-fz(40);
    letter-spacing: calc-fz(1.2);
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
  .page_ttl-en {
    margin-bottom: rem(56);
    font-size: calc-fz(24);
    letter-spacing: calc-fz(7.2);
    @include mq(sp) {
      font-size: sprem(14);
    }
  }
  .breadcrumbs {
    right: 0;
    top: 0;
    bottom: auto;
  }
}

/* --- page_ttl（タイトル画像なし） --- */
.page_ttl:not(.page_ttl-has_bg) {
  display: flex;
  justify-content: center;
  flex-direction: column;
  column-gap: rem(24);
  height: rem(478);
  @include mq(sp) {
    height: sprem(240);
  }
  .breadcrumbs {
    @include mq(sp, min, ps) {
      text-align: right;
    }
    @include mq(sp) {
      margin-bottom: sprem(16);
      text-align: left;
    }
  }
  .breadcrumbs {
    text-align: right;
    @include mq(sp) {
      text-align: left;
    }
  }
  .page_ttl-en {
    @include mq(sp) {
      margin-bottom: sprem(4);
    }
  }
  .page_ttl-jp {
    @include mq(sp) {
      margin-bottom: sprem(8);
    }
  }
}

@include mq(sp) {
  .page_simple .breadcrumbs:last-child {
    display: none;
  }
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  height: rem(380);
  @include mq(sp) {
    height: sprem(170);
  }
  & > .inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .breadcrumbs {
    @include mq(sp, min, ps) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .breadcrumbs span span:not(:last-child)::after {
    background-color: var(--clr-wht);
  }
}

/* ttl
********************************************** */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04,
.ttl-05,
.ttl-06 {
  position: relative;
  letter-spacing: 0.1em;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
  span {
    line-height: inherit;
    letter-spacing: inherit;
  }
}
.ttl-01 {
  margin-bottom: rem(64) !important;
  color: var(--clr-text-primary);
  font-size: calc-fz(48);
  @include mq(sp) {
    margin-bottom: sprem(32) !important;
    font-size: sprem(32);
  }
  &.font-en {
    font-size: rem(68);
    @include mq(sp) {
      font-size: sprem(40);
    }
  }
  .subtitle {
    margin-top: rem(24);
    @include mq(sp) {
      margin-top: sprem(8);
    }
  }
  &.clr-wht .subtitle {
    color: var(--clr-wht);
    &::after {
      background-color: var(--clr-wht);
    }
  }
}
.ttl-02 {
  margin-block: rem(120) rem(48) !important;
  padding-top: rem(32);
  font-size: calc-fz(40);
  letter-spacing: calc-fz(1.44);
  @include mq(sp) {
    padding-top: sprem(20);
    margin-block: sprem(64) sprem(24) !important;
    font-size: sprem(28);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    inset-inline: 0;
    margin: auto;
    @include rect(64,1);
    background-color: var(--clr-text-secondary);
  }
  &.font-en {
    font-size: rem(48);
    @include mq(sp) {
      font-size: sprem(30);
    }
  }
}
.ttl-03 {
  margin-block: rem(32) !important;
  font-size: rem(40);
  letter-spacing: calc-fz(1.2);
  @include mq(sp) {
    font-size: sprem(24);
  }
  &.font-en {
    font-size: rem(40);
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
}
.ttl-04 {
  font-size: rem(32);
  @include mq(sp) {
    font-size: sprem(20);
  }
  &.font-en {
    font-size: rem(32);
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
}
.ttl-05 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(22);
  }
  &.font-en {
    font-size: rem(24);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
}
.ttl-06 {
  font-size: calc-fz(20);
  @include mq(sp) {
    font-size: sprem(16);
  }
  &.font-en {
    font-size: rem(20);
    @include mq(sp) {
      font-size: sprem(18);
    }
  }
}

/* ttl-deco
********************************************** */
.ttl-deco {
  display: block;
  margin-bottom: perc(24, 16, em);
}

/* subtitle
********************************************** */
.subtitle,
.subtitle-sm {
  letter-spacing: calc-fz(7.2) !important;
}
.subtitle {
  display: block;
  color: var(--clr-text-primary);
  font-size: calc-fz(18);
  font-weight: 600;
  @include mq(sp) {
    font-size: sprem(16);
  }
  &.font-en {
    font-size: calc-fz(24);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  &.txt-ctr::after {
    @include auto-margin;
  }
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
}
.subtitle-sm {
  display: block;
  font-size: calc-fz(14);
  @include mq(sp) {
    font-size: sprem(12);
  }
  &.font-en {
    font-size: rem(14);
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
}
.kazari-en {
  font-size: rem(152);
  @include mq(sp) {
    font-size: sprem(56 );
  }
}