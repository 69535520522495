@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

.main_side {
  display: grid;
  grid-template-columns: minmax(0,1fr) rem(264);
  gap: rem(80);
  @include mq(med) {
    grid-template-columns: repeat(1,minmax(0,1fr));
    gap: sprem(40);
  }
}

/* main_column
**************************************** */

/* --- post --- */
.post {
  position: relative;
  &:hover {
    .post--img {
      img {
        opacity: .8;
        transform: scale(1.2,1.2);
        transition: 1s all;
      }
    }
  }
}
.post--award {
  width: rem(140) !important;
  top: rem(-64);
  left: rem(24);
  @include mq(sp) {
    width: sprem(72) !important;
    top: sprem(-32);
    left: sprem(8);
  }
}
.post--img {
  overflow: clip;
  img {
    transition: var(--transit-default);
  }
}
.post--img .img-contain {
  max-width: 80%;
}
.post--link {
  &:not([href="javascript:void(0);"]):hover {
    opacity: .7;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.post--date {
  font-family: var(--font-en);
  color: #5a5140;
  font-size: calc-fz(18);
  letter-spacing: .3em;
  @include mq(sp) {
    font-size: sprem(14);
  }
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: rem(2);
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: calc-fz(18);
  letter-spacing: .3em;
  line-height: 1;
  color: #5a5140;
  &:not(:last-child)::after {
    content: "／";
  }
}
.cat_list a:hover {
  opacity: .7;
}

/* side_column
**************************************** */
.side_section:not(:last-child) {
  margin-bottom: rem(64);
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.side--ttl {
  letter-spacing: .2em;
  line-height: 1;
  margin-bottom: rem(16);
}
.side--ttl span {
  display: block;
  margin-top: rem(8);
  line-height: 1;
  letter-spacing: .15em;
  text-transform: uppercase;
  opacity: .5;
}

/* --- list --- */
.side--list a {
  display: block;
}
.side--list .children a {
  position: relative;
  padding-left: 1.5em;
  &::before {
    content: '';
    position: absolute;
    top: .75em;
    left: 0;
    display: block;
    width: 1em;
    border-top: solid 1px;
  }
}

/* --- post --- */
.posts-side {
  .post {
    display: grid;
    grid-template-columns: rem(64) minmax(0,1fr);
    gap: rem(16);
    align-items: center;
    &:not(:last-child) {
      margin-bottom: rem(16);
    }
  }
  .post--date {
    margin-bottom: 0;
  }
  .post--ttl {
    line-height: var(--line-height-hdr);
  }
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
  .archive_list {
    position: relative;
    &:not(:last-child) {
      margin-right: rem(24);
    }
  }
  .archive_list a {
    display: block;
    padding: .25em 1em;
    text-align: left;
    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-bg-02);
    }
    &:hover {
      background-color: var(--clr-bg-02);
    }
  }
  .archive_list--label {
    margin-right: rem(8);
  }
  .archive_list--btn {
    cursor: pointer;
    border: none;
    outline: none;
    appearance: none;
    padding: .25em 4em .25em 2em;
    background-color: var(--clr-wht);
    border: 1px solid var(--clr-mono-02);
    color: inherit;
    font-family: var(--font-primary);
    font-size: calc-fz(16);
    line-height: var(--line-height-default);
    font-weight: 500;
    transition: var(--transit-default);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: .4em;
      right: .8em;
      margin: auto;
      width: .6em;
      height: .6em;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
    &:hover {
      background-color: var(--clr-bg-02);
    }
  }
  .active .archive_list--btn::after {
    bottom: -.2em;
    transform: rotate(225deg);
  }
  .archive_list--menu {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: var(--clr-wht);
    border: 1px solid var(--clr-mono-02);
    visibility: hidden;
    margin-top: -1px;
    opacity: 0;
    transition: var(--transit-default);
  }
  .active .archive_list--menu {
    visibility: visible;
    opacity: 1;
  }
}

/* art
**************************************** */
.posts-art {
  column-gap: rem(40);
  @include mq(med, min, ps) {
    margin-right: rem(-40);
  }
  @include mq(med) {
    column-gap: sprem(16);
    margin-right: sprem(-16);
  }
  .post--label {
    padding: rem(3) rem(29);
    color: var(--clr-wht);
    line-height: 1.6;
    white-space: nowrap;
    background-color: var(--clr-mono-06);
    border-radius: 999px;
    @include mq(sp) {
      padding: sprem(2) sprem(8);
      width: fit-content !important;
    }
  }
  .post--txt {
    line-height: 1.4;
  }
  .post--img {
    background-color: var(--clr-wht);
  }
  .post {
    margin-top: rem(120);
    @include mq(sp) {
      margin-top: sprem(56);
    }
    @include mq(med, min, ps) {
      &:nth-child(3n+1) {
        padding-right: rem(40);
        border-right: 1px solid var(--clr-mono-03);
      }
      &:nth-child(3n+2) {
        padding-right: rem(40);
        border-right: 1px solid var(--clr-mono-03);
      }
      &:nth-child(3n) {
        padding-right: rem(40);
      }
    }
    @include mq(med) {
      &:nth-child(odd) {
        padding-right: sprem(16);
        border-right: 1px solid var(--clr-mono-03);
      }
      &:nth-child(even) {
        padding-right: sprem(16);
      }
    }
    .post--img {
      padding: rem(24);
      aspect-ratio: 1;
      background-color: var(--clr-text-primary);
    }
    .post--img img {
      height: 100%;
      width: auto !important;
      max-width: 100%;
      transition: 1s all;
    }
  }
  .modal {
    display: none;
    height: 100vh;
    position: fixed;
    inset: 0;
    margin: auto;
    backdrop-filter: blur(rem(22));
    z-index: 99999;
    @include mq(med) {
      width: 100%;
      height: 100svh;
    }
  }
  .modal__bg {
    background: #080D10;
    opacity: .9;
    position: fixed !important;
    width: 100%;
    height: 100%;
    left: 0 !important;
    top: 0;
  }
  .modal__content{
    position: absolute;
    inset: 0;
    width: 100%;
    overflow: scroll;
    @include mq(sp, min, ps) {
      padding-inline: rem(142) rem(210);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @include mq(sp) {
      padding: sprem(64) sprem(24);
    }
    &-inner {
      @include mq(sp, min, ps) {
        max-height: 90vh;
        margin-left: auto;
        padding-left: 50%;
      }
      @include mq(sp) {
        
      }
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--clr-text-midlight);
        width: 8px;
        border-radius: 5px;
      }
      &:has(.mdl-art--awards) {
        .mdl-art--img_wrap {
          @include mq(sp, min, ps) {
            position: fixed;
            top: rem(120);
            left: rem(142);
            padding-top: rem(64);
            width: rem(672);
          }
        }
        .post--award {
          width: rem(208) !important;
          top: rem(-48);
          left: rem(-48);
          @include mq(sp) {
            width: sprem(100) !important;
            left: 0;
          }
        }
      }
    }
  }
  .mdl-art {
    &--img {
      padding: rem(24);
      aspect-ratio: 1;
      background-color: var(--clr-text-primary);
      @include mq(sp) {
        width: 80%;
        margin: auto;
      }
      img {
        height: 100%;
        width: auto !important;
      }
    }
    &--info {
      @include mq(sp, min, ps) {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        padding-block: rem(80);
      }
      @include mq(sp) {
        width: 90% !important;
        margin: auto;
      }
    }
    &--awards span {
      padding: rem(4) rem(24);
      line-height: 1.7;
      font-size: calc-fz(24);
      border-radius: rem(30);
      background: var(--clr-text-primary);
      @include mq(sp) {
        padding: sprem(4) sprem(12);
        font-size: sprem(12);
      }
      &.award-award-01 { background-color: #B68D0B; }
      &.award-award-02 { background-color: #E26347; }
      &.award-award-03 { background-color: #8D866D; }
      &.award-award-04 { background-color: #8D8D93; }
      &.award-award-05 { background-color: #B68D0B; }
      &.award-award-06 { background-color: #B68D0B; }
      &.award-award-07 { background-color: #B68D0B; }
      &.award-award-08 { background-color: #B68D0B; }
      &.award-award-09 { background-color: #8D866D; }
      &.award-award-10 { background-color: #8D866D; }
      &.award-award-11 { background-color: #8D8D93;}
      &.award-award-12 { background-color: #8D8D93;}
    }
    &--ttl {
      line-height: 1.4;
    }
    &--author {
      letter-spacing: perc(.4, 20, em) !important;
    }
    &--detail {
      dt {
        display: inline-block;
        padding: rem(8) rem(16);
        font-size: calc-fz(18);
        line-height: 1.6;
        @include mq(sp) {
          font-size: sprem(14);
          padding: rem(4) rem(12);
        }
      }
      &_cmt01 dt {
        color: var(--clr-text-primary);
        background-color: var(--clr-text-placeholder);
      }
      &_cmt02 dt {
        background-color: var(--clr-text-secondary);
      }
    }
  }
  .modal__close-btn {
    position: fixed;
    top: rem(82);
    right: rem(68);
    text-align: center;
    @include mq(sp) {
      right: sprem(24);
      top: sprem(24);
      width: auto !important;
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .mdl_btn--txt {
    line-height: 1;
    letter-spacing: perc(3.2, 16, em) !important;
    color: var(--clr-wht);
    transition: var(--transit-default);
    @include mq(sp) {
      font-size: sprem(10);
      letter-spacing: sprem(2);
    }
  }
  .mdl_btn--lines {
    position: relative;
    width: rem(64);
    height: rem(36);
    @include mq(sp, min, ps) {
      margin: auto;
    }
    @include mq(med) {
      @include sprect(35, 12);
    }
  }
  .mdl_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--clr-wht);
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(1) {
      transform: translateY(rem(17)) rotate(-30deg);
      @include mq(sp) {
        transform: translateY(sprem(5)) rotate(-15deg);
      }
    }
    &:nth-of-type(2) {
      transform: translateY(0) rotate(30deg);
      @include mq(sp) {
        transform: translateY(sprem(-1)) rotate(15deg);
      }
    }

  }
}


/* blog
**************************************** */
.posts-blog {
  .post {
    display: grid;
    gap: rem(40);
    padding-block: rem(48);
    border-bottom: 1px solid var(--clr-mono-02);
    @include mq(sp, min, ps) {
      grid-template-columns: rem(280) minmax(0,1fr);
      align-items: center;
    }
    @include mq(sp) {
      gap: sprem(24);
      padding-block: sprem(32);
    }
    &:first-child {
      border-top: 1px solid var(--clr-mono-02);
    }
  }
  .post--txtarea {
    flex: 1;
    word-break: break-word;
  }
  .post--ttl {
    font-size: calc-fz(18);
  }
  .post--txt {
    letter-spacing: perc(.4, 20, em) !important;
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--clr-wht);
}

.article-news--info .ttl-02 {
  margin-top: rem(48) !important;
}

/* --- posts-news --- */
.posts-news {
  .post {
    display: grid;
    grid-template-columns: rem(210) 1fr;
    gap: rem(40);
    padding-bottom: rem(48);
    padding-top: rem(48);
    border-bottom: 1px solid #9c9992;
    @include mq(sp) {
      grid-template-columns: repeat(1,minmax(0,1fr));
    }
  }
  .post--label {
    margin-right: rem(16);
    padding: rem(4) rem(16);
    color: var(--clr-wht);
    line-height: 1.6;
    white-space: nowrap;
    background-color: #5a5140;
    @include mq(sp) {
      padding: sprem(6) sprem(8);
    }
  }
  .post--info {
    @include mq(sp) {
      margin-bottom: sprem(8);
    }
  }
  .post--date {
    font-size: calc-fz(16);
    letter-spacing: .16em;
  }
  .post--txtarea {
    height: 100%;
    column-gap: rem(40);
  }
  .post--ttl {
    font-size: calc-fz(25);
    @include mq(med) {
      font-size: sprem(18);
      margin-bottom: sprem(8);
    }
  }
  .post--info.flex .post--date + .cat_list {
    margin-left: rem(16);
  }  
}


/* --- posts-news--hero --- */
.posts-news--hero {
  .post {
    display: grid !important;
    grid-template-columns: rem(120) 1fr;
    gap: rem(16);
    padding-block: rem(4);
    @include mq(sp) {
      grid-template-columns: sprem(72) 1fr;
      padding-block: sprem(8);
    }
  }
  .post--img {
    aspect-ratio: 119/90;
  }
  .post--date {
    font-size: calc-fz(16);
    color: var(--clr-wht);
    letter-spacing: .16em;
    @include mq(med) {
      font-size: sprem(14);
    }
  }
  .post--label {
    margin-right: rem(16);
    padding: rem(2) rem(8);
    color: var(--clr-wht);
    font-size: calc-fz(14);
    line-height: 1.6;
    white-space: nowrap;
    background: none;
    border: 1px solid var(--clr-wht);
    @include mq(sp) {
      margin-right: sprem(8);
      font-size: sprem(12);
      line-height: 1.4;
      padding: sprem(4) sprem(4) sprem(6);
    }
  }
  .post--info {
    @include mq(sp) {
      margin-bottom: sprem(8);
    }
  }
  .post--txtarea {
    height: 100%;
  }
  .post--ttl {
    font-size: calc-fz(18);
    @include mq(med) {
      font-size: sprem(14);
    }
  }
}