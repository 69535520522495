@use "../abstracts" as *;

/* hero
********************************************** */
.hero {
  position: relative;
  height: 100vh;
}
.hero--cont {
  position: absolute;
  top: rem($header-hgt-top);
  bottom: 0;
  right: rem(256);
  height: fit-content;
  margin-top: rem(16);
  transition-delay: .5s;
  @include mq(med) {
    top: sprem(184);
    right: sprem(40);
    width: sprem(80)
  }
}
.hero--catch {
  margin-bottom: rem(32);
  font-size: calc-fz(40);
  p {
    font-family: var(--font-jp-lgt);
    line-height: 1.83;
    letter-spacing: 0.22em;
    @include write-v;
    @include mq(med) {
      line-height: 1.2;
      letter-spacing: .1em;
    }
  }
}
.hero--lead {
  font-size: calc-fz(18);
  line-height: 1.6;
  letter-spacing: .0054em;
}
.hero--event {
  position: absolute;
  bottom: rem(44);
  left: rem(128);
  transition-delay: 1.5s;
  @include mq(med) {
    inset-inline: sprem(35);
    bottom: sprem(32);
  }
}
.hero--message {
  position: absolute;
  top: calc(100% + rem(12));
  display: block;
  width: rem(740);
  opacity: .5;
  pointer-events: none;
  transition: var(--transit-default);
  @include mq(med) {
    display: none;
  }
  p {
    letter-spacing: .12em;
    line-height: 1.64;
  }
}
.hero_slide {
  overflow: hidden;
  .add-animation img {
    animation: photo-scale 14s linear;
  }
}
.hero_slide-sp {
  display: none !important;
}
.hero_slides,
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}

@include mq(sp) {
  .hero {
    height: 100vh;
  }
  .hero_slide-pc {
    display: none !important;
  }
  .hero_slide-sp {
    display: block !important;
  }
}

/* home_news
********************************************** */
.home_news {
  > .inner {
    border-top: 1px solid #9c9992;
    padding-top: rem(104);
    @include mq(med, min, ps) {
      display: grid;
      grid-template-columns: rem(345) 1fr;
      gap: rem(28);
    }
  }
  &--btn {
    margin-top: rem(48);
    a {
      margin-left: auto;
    }
  }
}
.home_news .posts-news {
  flex: 1;
  @include mq(med, min, ps) {
    margin-top: rem(56);
  }
}
.posts-news--ttl_en {
  letter-spacing: .3em;
  margin-bottom: rem(24);
}
.posts-news--ttl {
  display: block;
  position: relative;
  letter-spacing: .22em;
  margin-right: rem(40);
  padding: 0;
  line-height: 1.2;
  @include mq(sp) {
    width: auto;
    float: none;
    height: auto;
  }
}

.home_subttl {
  letter-spacing: .3em;
}

/* home_info
********************************************** */
.home_info {
  @include mq(sp) {
  }
  > .inner {
    border-bottom: 1px solid #9c9992;
    @include mq(med, min, ps) {
      width: rem(1812);
      max-width: rem(1812);
      padding-block: rem(60) rem(35);
      padding-inline: rem(75);
    }
    @include mq(med) {
      width: 100%;
      padding-block: sprem(42);
      padding-inline: sprem(36);
    }
  }
  &--lft {
    column-gap: rem(26);
    .label {
      margin-left: rem(8);
    }
  }
  &--part {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.78;
  }
  @include mq(med, min, ps) {
    &--part:not(:last-child) {
      border-right: 1px solid #9c9992;
    }
  }
  &--part_inner {
    letter-spacing: .05em;
    line-height: 1.4;
    @include mq(med, min, ps) {
      width: fit-content;
    }
    @include mq(med) {
      display: flex;
      align-items: center;
      gap: rem(18);
      width: 100%;
    }
    .font-en {
      font-weight: 400;
    }
  }
}

/* home_about
********************************************** */
.home_about {
  overflow-x: clip;
  @include mq(sp) {
  }
  &::before {
    content: "";
    position: absolute;
    top: rem(-42);
    left: rem(-120);
    @include rect(973,969);
    background: url(../images/top-sousyoku.png) no-repeat center center/cover;
    z-index: -1;
    @include mq(med) {
      width: sprem(300);
      height: auto;
      aspect-ratio: 973/969;
      background: url(../images/top-sousyoku.pngg) no-repeat center center / cover;
      left: 0;
      top: sprem(-40);
    }
  }
  @include mq(med, min, ps) {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: rem(-120);
      @include rect(932,969);
      background: url(../images/common/sec-bg@2x.png) no-repeat center center/cover;
      z-index: -1;
    }
  }
  &--ttl {
    font-size: calc-fz(40);
    line-height: 1.6;
    @include mq(med) {
      font-size: sprem(28);
      line-height: 1.4;
    }
    span {
      letter-spacing: .2em;
    }
  }
  &--entxt {
    line-height: 1.5;
    font-weight: 400;
  }
  &--slider_wrap {
    @include mq(med, min, ps) {
      position: sticky;
      top: calc(50% - rem(860) / 2);
      @include rect(800,860);
    }
    @include mq(med) {
      margin-bottom: sprem(40);
      aspect-ratio: 800/860;
    }
  }
  &--slide {
    aspect-ratio: 389/871;
    img {
      max-width: inherit;
      width: calc(100% + rem(100));
      // margin-right: rem(-100);
      height: 100%;
      object-fit: cover;
      // transform: translateX(rem(-100)); 
    }
    &.preve-slide img,
    &.slick-active img {
      animation: photo-move 14s linear;
    }
  }
  // &--slide:nth-child(odd).slick-active img {
  //   animation: photo-move2 5s linear;
  // }
  &--btn a {
    width: rem(404);
  }
}


/* home_appeal
********************************************** */
.home_appeal {
  z-index: 2;
  position: relative;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    top: rem(640);
    left: rem(-40);
    @include rect(506,622);
    background: url(../images/appeal-soushoku.png) no-repeat center center/cover;
    z-index: -1;
    @include mq(med) {
      width: sprem(240);
      height: auto;
      aspect-ratio: 506/622;
      left: 0;
      top: sprem(500);
    }
  }
  &--toparea {
    height: 500vh;
    @include mq(sp) {
      height: 400vh;
    }
    &--inner {
      position: sticky;
      top: 0;
    }
  }
  &--mv_wrap picture {
    @include mq(sp, min, ps) {
      height: 100vh;
      width: 100%;
    }
  }
  &--mv {
    width: 100%;
    height: 100vh;
    transition: var(--transit-default);
    &-catch {
      li {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: rem(24);
        inset: 0;
        margin: auto;
        color: var(--clr-wht);
        opacity: 0;
        transition: 1s;
        &.active {
          opacity: 1;
        }
        .font-en {
          letter-spacing: .3em;
        }
        .ttl {
          font-family: var(--font-jp-lgt);
          letter-spacing: .22em;
        }
      }
    }
  }
  &--btmarea {
    position: relative;
    margin-top: -200vh;
    padding-inline: rem(160);
    transition: var(--transit-default);
    @include mq(med) {
      padding-inline: sprem(32);
    }
    &::before {
      content: "";
      position: absolute;
      inset: -100vh 0 0;
      background-color: #000;
      opacity: 0;
      transition: var(--transit-default);
    }
    &.active {
      &::before { opacity: 1; }
      .home_appeal--slider_wrap,
      .home_appeal--contents {
        opacity: 1;
      }
    }
  }
  &--contents {
    height: 600vh;
    counter-reset: number 0; 
    opacity: 0;
    transition: var(--transit-default);
    @include mq(med, min, ps) {
      padding-left: rem(60);
    }
  }
  &--slider_wrap {
    @include mq(med, min, ps) {
      position: sticky;
      top: 50vh;
      @include rect(480);
      aspect-ratio: 1;
      transform: translate(50%, -50%);
      overflow: clip;
      opacity: 0;
      z-index: 99;
      transition: var(--transit-default);
    }
    @include mq(med) {
      position: sticky;
      top: calc(sprem(40) + 20vw);
      margin: auto;
      width: 60vw !important;
      height: calc(100% - sprem(40));
      aspect-ratio: 1;
      overflow: clip;
      opacity: 0;
      z-index: 99;
      transition: var(--transit-default);
    }
  }
  &--slide {
    &:not(:last-child) {
      margin-bottom: rem(40);
      @include mq(med) {
        margin-bottom: 10vw
      };
    }
    img {
      width: 100% !important;
    }
  }
  &--content {
    position: sticky;
    top: 0;
    display: flex;    
    flex-direction: column;
    align-items: start;
    justify-content: center;
    height: 100vh;
    pointer-events: none;
    @include mq(med) {
      padding-top: calc(50vh - sprem(40));
    }
    &.active {
      &::before {
        opacity: 1;
      }
      .home_appeal--content--txtbox {
        opacity: 1;
      }
    }
    &::before {
      content: "";
      position: fixed;
      inset: 0;
      opacity: 0;
      z-index: -1;
      transition: var(--transit-default);
    }
    &:nth-child(1)::before {
      background: url(../images/appeal-slide-l-01.jpg) no-repeat center center/cover;
      @include mq(sp) {
        background: url(../images/appeal-slide-l-01-sp.jpg) no-repeat center center/cover;
      }
    }
    &:nth-child(2)::before {
      background: url(../images/appeal-slide-l-02.jpg) no-repeat center center/cover;
      @include mq(sp) {
        background: url(../images/appeal-slide-l-02-sp.jpg) no-repeat center center/cover;
      }
    }
    &:nth-child(3)::before {
      background: url(../images/appeal-slide-l-03.jpg) no-repeat center center/cover;
      @include mq(sp) {
        background: url(../images/appeal-slide-l-03-sp.jpg) no-repeat center center/cover;
      }
    }
    &:nth-child(4)::before {
      background: url(../images/appeal-slide-l-04.jpg) no-repeat center center/cover;
      @include mq(sp) {
        background: url(../images/appeal-slide-l-04-sp.jpg) no-repeat center center/cover;
      }
    }
    &:nth-child(5)::before {
      background: url(../images/appeal-slide-l-05.jpg) no-repeat center center/cover;
      @include mq(sp) {
        background: url(../images/appeal-slide-l-05-sp.jpg) no-repeat center center/cover;
      }
    }
    &--txtbox {
      position: relative;
      width: 90%;
      opacity: 0;
      transition: 1s;
      @include mq(med) {
        width: 100%;
      }
      &::before {
        counter-increment: number 1;
        content: counter(number, decimal-leading-zero);
        position: absolute;
        right: calc(100% + rem(8));
        bottom: 100%;
        font-family: var(--font-en);
        color: var(--clr-wht);
        white-space: nowrap;
        letter-spacing: 0;
        font-weight: 300;
        @include mq(med) {
          right: calc(100% - sprem(5));
          bottom: calc(100% + sprem(8));
        }
      }
      &::after {
        content: "";
        position: absolute;
        right: calc(100% - rem(8));
        bottom: calc(100% + .5rem);
        @include rect(28,1);
        background-color: #9c9992;
        rotate: -45deg;
        @include mq(med) {
          right: calc(100% - sprem(20));
          bottom: calc(100% + sprem(10));
        }
      }
    }
    &--ttl {
      font-size: calc-fz(32);
      margin-bottom: rem(18);
      @include mq(med) {
        font-size: sprem(24);
        margin-bottom: sprem(12);
      }
    }
    &--ttl_en {
      margin-bottom: rem(64);
      @include mq(med) {
        margin-bottom: sprem(24);
      }
    }
    &--txt {
    }
  }
}

/* home_gallery
********************************************** */
.home_gallery {
  height: 100vh;
  overflow: clip;
  // opacity: 0;
  // transition: var(--transit-default);
  // &.active {
  //   opacity: 1;
  // }
  @include mq(med, min, ps) {
    margin-top: -120vh;
  }
  @include mq(med) {
    margin-top: -120vh;
  }

  @include mq(sp) {
  }
  &--ttl {
    font-size: calc-fz(26);
    letter-spacing: .3em;
    @include mq(med) {
      font-size: sprem(18);
    }
  }
  &--btn a {
    width: rem(404);
    text-align: left;
  }
  &--bg_wrap {
    position: absolute;
    inset-inline: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    &.active {
      animation: scrollAnimation 50s linear infinite; 
      @include mq(sp) {
        animation: scrollAnimation_sp 50s linear infinite; 
      }
    }
  }
  &--bg_imgs {
    height: rem(3000);
    @include mq(sp) {
      height: sprem(2300);
    }
  }
  &--bg_imgs li:nth-child(1) {
    @include mq(sp, min, ps) {
      top: rem(69);
      left: rem(38);
    }
    @include mq(sp) {
      top: sprem(114);
      left: 0;
    }
  }
  &--bg_imgs li:nth-child(2) {
    @include mq(sp, min, ps) {
      top: rem(475);
      left: rem(420);
    }
    @include mq(sp) {
      top: sprem(586);
      right: 0;
    }
  }
  &--bg_imgs li:nth-child(3) {
    @include mq(sp, min, ps) {
      top: rem(1030);
      left: rem(130);
    }
    @include mq(sp) {
      top: sprem(1600);
      right: sprem(51);
    }
  }
  &--bg_imgs li:nth-child(4) {
    @include mq(sp, min, ps) {
      top: rem(1646);
      left: rem(441);
    }
    @include mq(sp) {
      top: sprem(474.5);
      left: sprem(7);
    }
  }
  &--bg_imgs li:nth-child(5) {
    @include mq(sp, min, ps) {
      bottom: rem(547);
      left: rem(82);
    }
    @include mq(sp) {
      top: sprem(1431.5);
      left: sprem(21);
    }
  }
  &--bg_imgs li:nth-child(6) {
    @include mq(sp, min, ps) {
      top: rem(50);
      left: rem(960);
    }
    @include mq(sp) {
      top: sprem(25);
      right: sprem(61);
    }
  }
  &--bg_imgs li:nth-child(7) {
    @include mq(sp, min, ps) {
      top: rem(1009);
      left: rem(827);
    }
    @include mq(sp) {
      top: sprem(750);
      left: sprem(25);
    }
  }
  &--bg_imgs li:nth-child(8) {
    @include mq(sp, min, ps) {
      top: rem(150);
      right: rem(86);
    }
    @include mq(sp) {
      top: sprem(327);
      right: sprem(15);
    }
  }
  &--bg_imgs li:nth-child(9) {
    @include mq(sp, min, ps) {
      top: rem(634);
      right: 0;
    }
    @include mq(sp) {
      top: sprem(943);
      right: 0;
    }
  }
  &--bg_imgs li:nth-child(10) {
    @include mq(sp, min, ps) {
      top: rem(1307);
      right: rem(335);
    }
    @include mq(sp) {
      top: sprem(1263.5);
      right: sprem(21);
    }
  }
  &--bg_imgs li:nth-child(11) {
    @include mq(sp, min, ps) {
      bottom: rem(608);
      right: rem(28);
    }
    @include mq(sp) {
      top: sprem(1892.5);
      left: sprem(40);
    }
  }
  &--bg_imgs li:nth-child(12) {
    @include mq(sp, min, ps) {
      bottom: rem(105);
      right: rem(592);
    }
    @include mq(sp) {
      top: sprem(2107);
      right: sprem(82.5);
    }
  }
}

/* home_exhibit
********************************************** */
.home_exhibit {
  overflow: clip;
  @include mq(sp) {
  }
  &::before {
    content: "";
    position: absolute;
    top: rem(32);
    left: rem(-300);
    @include rect(942,886);
    background: url(../images/common/sec-bg2.png) no-repeat center center/cover;
    z-index: -1;
    @include mq(med) {
      width: sprem(320);
      height: auto;
      aspect-ratio: 650 / 861;
      left: 0;
      top: sprem(80);
      rotate: 90deg;
    }
  }
  > .inner {
    @include mq(med, min, ps) {
      display: grid;
      grid-template-columns: rem(545) 1fr;
    }
  }
  &--ttlen {
    letter-spacing: .3em;
  }
  &--ttl {
    font-size: calc-fz(40);
    letter-spacing: .22em;
    line-height: 1.83;
    @include mq(med) {
      font-size: sprem(28);
    }
    
  }
  &--list {
    margin-top: rem(24);
  }
  &--item {
    @include mq(med, min, ps) {
      &:nth-child(1) {
        padding-top: rem(150);
        transition-delay: .5s;
      }
      &:nth-child(2) {
        transition-delay: .75s;
      }
      &:nth-child(3) {
        padding-top: rem(174);
        transition-delay: 1s;
      }
    }
    @include mq(med) {
      img {
        width: 100% !important;
      }
    }
    &--ttl_en {
      letter-spacing: .3em;
    }
    &--ttl {
      @include mq(med) {
        margin-left: .8em;
      }
    }
  }
}

/* home_visual
********************************************** */
.home_visual .swiper-container {
  position: absolute;
  inset: 0;
  @include mq(med, min, ps) {
    height: 100vh;
  }
  @include mq(med) {
    height: sprem(480);
  }
}
.home_visual .swiper-container .slide {
  width: calc(100% + 120px);
  @include mq(sp) {
    width: calc(100% + 60px);
  }
}
.home_visual {
  position: relative;
  @include mq(med, min, ps) {
    height: 100vh;
  }
  @include mq(med) {
    height: sprem(480);
  }
  > .inner {
    position: relative;
    z-index: 999;
  }
  &--textarea {

  }
  &--ttl {
    font-family: var(--font-jp-lgt);
    font-size: calc-fz(40);
    letter-spacing: .24em;
    line-height: 1.8;
    @include mq(med) {
      font-size: sprem(28);
    }
  }
  &--ttl_en {
    letter-spacing: .43em;
    margin-bottom: rem(24);
  }
}

/* home_
********************************************** */
.home_ {
  @include mq(sp) {
  }
}
